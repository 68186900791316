import React from 'react';
import Script from 'next/script';
import { ClerkProvider } from '@clerk/nextjs';
import { enUS, ruRU } from '@clerk/localizations';
import { appWithTranslation, useTranslation } from 'next-i18next';

import '../styles/globals.css';
import '../assets/scss/style.scss';
import { SetupGoogleAdsTagScript } from '@scripts/gtag/SetupGoogleAdsTagScript';
import { SetupGoogleAnalyticsTagScript } from '@scripts/gtag/SetupGoogleAnalyticsTagScript';

const localeToClerkDictMap = {
  en: enUS,
  ru: ruRU,
};

function MyApp({ Component, pageProps }) {
  const { i18n } = useTranslation();

  return (
    <>
      <SetupGoogleAdsTagScript />
      <SetupGoogleAnalyticsTagScript />
      <React.Fragment>
        <Script
          defer
          data-domain="aigirlfriend.wtf"
          src="https://plausible.aigirlfriend.wtf/js/script.tagged-events.js"
        ></Script>
      </React.Fragment>

      <ClerkProvider
        localization={localeToClerkDictMap[i18n.language]}
        {...pageProps}
      >
        <Component {...pageProps} />
      </ClerkProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
